const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];
const extraFitmentFields = globalThis.Convermax.config?.extraFitmentFields || ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const wheelsAndTiresFields = [...wheelsFields, ...tiresFields, 'wheel_backspace'];
const facetBarFields = [...wheelsFields, ...tiresFields];
const ignoreFields = [...facetBarFields, 'Universal'];

const vehicleStore = globalThis.Convermax?.fitmentSearch?.vehicleStore;
const vehicleLandingPageView = globalThis.Convermax.config?.vehicleLandingPageView ?? 'categories';

if (vehicleLandingPageView === 'products') {
  ignoreFields.push('Vehicle');
}

// if the theme has a currency selector for markets, the URL should contain a culture code based on the selected currency
const cultureCode = globalThis.Shopify?.routes?.root.slice(0, -1) ?? '';

const searchPageUrl = `${cultureCode}/search`;
const brandSelectionPageUrl = `${cultureCode}/pages/shop-by-brand`;
const categorySelectionPageUrl = `${cultureCode}/collections`;
const categorySelectionPageField = window.Convermax.config?.categorySelectionPageField ?? 'category';

const isDesktopWidth = globalThis.innerWidth >= 768;

const fieldsForRelated = ['category', 'collections', ...wheelsAndTiresFields];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}
globalThis.Convermax.facetPanelIgnoreField = (field) => {
  return (
    (window.Convermax.universalCollection && field === 'Fitment') ||
    (isDesktopWidth && facetBarFields.includes(field))
  );
};

globalThis.Convermax.productIsNew = (publishedAt) => {
  return (Date.now() - new Date(publishedAt)) / (1000 * 60 * 60 * 24) < window.themeSettings.time_new;
};

function getFitmentSearchTitle() {
  const { pageType } = window.ShopifyAnalytics.meta.page;

  const title = document.querySelector('body.template-collection h1.page-heading')?.textContent.trim();
  return pageType === 'collection' ? title : pageType === 'vendors' ? `${title} Parts` : 'Parts';
}

const handleCurrencies = (selector) => {
  // window.Currency.cookie.read() can cause exception "Uncaught TypeError: jQuery.cookie is not a function"
  try {
    // window.geoplugin_currencyCode() is a function that determines the currency based on the user's IP address
    // it can be enabled in the Spark theme
    const currentCurrency =
      typeof window.geoplugin_currencyCode === 'function'
        ? window.geoplugin_currencyCode()
        : window.Currency.cookie.read();

    if (currentCurrency) {
      window.Currency.convertAll(window.Shopify.currency.active, currentCurrency, selector);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.debug(e.message);
  }
};

const updateCallback = () => {
  handleCurrencies('.cm_SearchResult span.money');
  window.updateWishListButtons();

  if (window._affirm_config) {
    window.affirm.ui.ready(function () {
      window.affirm.ui.refresh();
    });
  }

  updateProductImageOnSwatchHover();
};

function InitFunc() {
  if ([categorySelectionPageUrl, brandSelectionPageUrl].includes(window.location.pathname) || vehicleStore) {
    document.body.classList.add('cm_category-selection');
    if (vehicleStore) {
      document.body.classList.add('cm_mini-store');
    }
  }
}

export default {
  platform: 'shopify',
  searchPageUrl,
  InitFunc,
  SearchRequestDefaults: {
    pageSize: window.Convermax.config?.pageSize || 36,
    selection: vehicleStore ? [{ field: 'Vehicle', term: vehicleStore }] : undefined,
  },
  requestTimeout: window.Convermax.config?.requestTimeout,
  page: {
    getPageType: (defaults) => (vehicleStore ? 'parts' : defaults.getter()),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: categorySelectionPageField },
      { pathname: brandSelectionPageUrl, field: 'vendor' },
      {
        pathname: vehicleStore && vehicleLandingPageView === 'categories' ? window.location.pathname : null,
        field: categorySelectionPageField,
      },
    ],
    onVehicleSelected: () => {
      const menuCloseElement = window.document.querySelector('#site-nav-mobile .close_menu');
      if (menuCloseElement) {
        menuCloseElement.click();
      }
    },
    categorySelectionPageRedirect: window.themeSettings?.enable_ymm_redirect ?? true,
    expectResponseRedirect: true,
    getFitmentSearchTitle,
    isAutoRedirectDisabled: window.Convermax.config?.isAutoRedirectDisabled ?? false,
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: { selector: '#cm_collection-page', class: 'cm_SearchPageCategory cm_main-content' },
      template: 'MainContent',
    },
    {
      name: 'CategoryFacetPanel',
      type: 'FacetPanel',
      location: { selector: '#cm_collection-facet-panel' },
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_categories-page',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brands-page',
      template: 'facetTiles/alphabeticalContainer',
      view: 'columns', // FIXME: 'grid' view not yet styled for the Spark theme
    },
    {
      name: 'SearchBox',
      location: '.halo-header-PC .header-search',
      template: 'SearchBox',
      onDropdownItemsReceived: () => handleCurrencies('.cm_search-box-root div.price'),
    },
    {
      name: 'SearchBoxDialogButton',
      location: '.item--searchMobile',
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'Garage',
      location: { selector: '#cm_Garage', class: 'item item--garage' },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SingleVehicleGarage',
      location: {
        selector: '#cm_SingleVehicleGarage',
        wrapper: 'li',
        class: 'menu-lv-1 item',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '.home-ymm-bar .themevale_MultiCategory_wrapper',
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: { lastChildOf: 'header.site-header', class: 'cm_vehicle-widget__header-container' },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      visibleIf: () =>
        window.themeSettings.enable_header_ymm && !window.document.body.classList.contains('template-index'),
      initCollapsed: true,
      columnBreakpoint: 700,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { selector: '#cm_verify-fitment', class: 'mb-4' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableTabDesktop',
      type: 'FitmentTable',
      location: '#cm_fitment-table_tab-desktop',
      template: 'fitmentSearch/fitmentTableTabDesktop',
    },
    {
      name: 'FitmentTableTabMobile',
      type: 'FitmentTable',
      location: '#cm_fitment-table_tab-mobile',
      template: 'fitmentSearch/fitmentTableTabMobile',
    },
    {
      name: 'FitmentTable',
      location: '#cm_fitmentTable',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'RelatedItems',
      location: '#cm_related-products',
      template: 'product/relatedItems',
      count: window.Convermax.productRecommendationsSettings?.limit || 8,
      getSelection,
      onItemsRendered: () => window.initRecommendationSlider(),
    },
    {
      name: 'WheelTireProductAttributes',
      type: 'ProductData',
      location: '#cm_wheel_tire_attributes',
      template: 'product/wheelTireProductAttributes',
      fields: wheelsAndTiresFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    {
      name: 'SearchResult',
      instantInfiniteScroll: !!window.Convermax.config?.instantInfiniteScrollEnabled,
      updateCallback,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
      onDropdownItemsReceived: () => handleCurrencies('.cm_search-box-root__dialog div.price'),
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};

function updateProductImageOnSwatchHover() {
  $('.product-color-swatch').each(function () {
    const optionImage = $(this).data('option-image');

    if ($(this).hasClass('show-more')) {
      $(this).click(function () {
        $(this).siblings('.product-color-swatch.hidden').removeClass('hidden');
        $(this).addClass('hidden');
      });
    }

    if (optionImage) {
      const productImage = $(`.product-card[data-product-id="${$(this).data('product-id')}"]`)
        .find('img')
        .first();
      const sourceImage = productImage.attr('src');

      $(this).mouseover(function () {
        productImage.attr('src', optionImage);
      });
      $(this).mouseout(function () {
        productImage.attr('src', sourceImage);
      });
    }

    if (!$(this).hasClass('show-more') && !$(this).attr('style').includes('background-color')) {
      $(this).css('font-size', '1em');
      $(this).css('width', 'unset');
    }
  });
}
