export default {
  BUTTON_PRIMARY_CLASS: 'btn btn--primary',
  BUTTON_SECONDARY_CLASS: 'btn btn--secondary',

  LOAD_MORE_TEXT: '<span>{window.themeSettings.locales.load_more}</span>',
  SORT_BY: '{window.themeSettings.locales.sort_select}',
  FACET_PANEL_CLASS: 'page-sidebar',
  PRODUCT_REPEATER_CLASS: 'halo-column halo-column-product',

  CURRENT_SEARCH: '{window.themeSettings.locales.current_search}',
  START_OVER: '{window.themeSettings.locales.start_over}',
  PRICE_FACET_GO_TEXT: '{window.themeSettings.locales.price_filter_button}',
  FILTERS_TEXT: '{window.themeSettings.locales.mobile_filter_button}',
  TOTAL_HITS: '{window.themeSettings.locales.total_results}',
  FACET_DIALOG_TITLE: '{window.themeSettings.locales.mobile_filter_dialog_title}',
  FACET_DIALOG_RESULTS: '{window.themeSettings.locales.mobile_filter_dialog_results}',
  FACET_DIALOG_CLEAR: '{window.themeSettings.locales.mobile_filter_dialog_clear}',
  FACET_DIALOG_DONE: '{window.themeSettings.locales.mobile_filter_dialog_done}',

  SEARCH_BOX_BUTTON_CLASS: 'btn-search icon-search',
  SEARCH_BOX_CLASS: 'input-group search-bar',
  SEARCH_BOX_PLACEHOLDER: '{window.themeSettings.locales.search_placeholder}',
  SEARCH_BOX_INPUT_CLASS: 'input-group-field',
  SEARCH_BOX_CLEAR_BUTTON: "{window.innerWidth > 768 ? '✕' : window.themeSettings.locales.search_clear}",
  BROWSE_ALL: '{window.themeSettings.locales.browse_all} ({{totalHits}})',

  VEHICLE_WIDGET_COLLAPSIBLE_CLASS: 'container',
  VEHICLE_WIDGET_COLLAPSIBLE_LABEL_CLASS: 'cm_vehicle-widget_title',
  SELECT_YOUR_VEHICLE_BLOCK: '{window.themeSettings.locales.ymm.select_block}',
  SELECT_YOUR_VEHICLE: '{window.themeSettings.locales.ymm.select_block}',
  CHANGE_VEHICLE: '{window.themeSettings.locales.ymm.change}',
  CHANGE_TEXT: '{window.themeSettings.locales.ymm.change}',
  DISCARD_VEHICLE: '{window.themeSettings.locales.ymm.clear}',
  GO_TEXT: '{window.themeSettings.locales.ymm.go}',
  CLEAR_TEXT: '<rt-virtual template="icons/reset" />',

  MIN_PRICE: '{window.themeSettings.locales.price_filter_min}',
  MAX_PRICE: '{window.themeSettings.locales.price_filter_max}',

  GARAGE_BUTTON_CLASS: 'navUser-text-wrapper',
  GARAGE_CLASS: 'navUser-action custom',
  GARAGE_ICON: '<div class="garage-icon cm_icon_garage__background icon"></div>',
  GARAGE_SIZE: '<span class="garage-size countPill" key="garage-size">{{size}}</span>',
  GARAGE_TITLE: '{window.themeSettings.locales.garage_title}',
  SINGLE_VEHICLE_GARAGE_CLEAR: '<rt-virtual template="icons/reset" />',
  CLEAR_GARAGE_TEXT: '{window.themeSettings.locales.clear_garage}',
  ADD_VEHICLE: '{window.themeSettings.locales.add_vehicle}',
  STORE_VEHICLE_IN_GARAGE: '{window.themeSettings.locales.garage_first_line}',
  GET_PRODUCTS_FOR_VEHICLE: '{window.themeSettings.locales.garage_second_line}',
  FIND_PARTS_YOU_NEED: '{window.themeSettings.locales.garage_third_line}',

  VERIFY_FITMENT_TITLE: '{window.themeSettings.locales.ymm.verify_fitment}',
  // VERIFY_FITMENT_FITS: '{window.themeSettings.locales.ymm.fits}',
  // VERIFY_FITMENT_NOT_FIT: '{window.themeSettings.locales.ymm.not_fits}',
  VERIFY_FITMENT_SPECIFY:
    '{window.themeSettings.locales.ymm.specify} {{fieldsToSpecify.join(", ")}} for your VERIFY_FITMENT_VEHICLE',
  FITMENT_UNIVERSAL_TITLE: '{window.themeSettings.locales.ymm.universal_title}',
  FITMENT_UNIVERSAL_TEXT: '{window.themeSettings.locales.ymm.universal_text}',
  FITMENT_UNKNOWN_TITLE: '{window.themeSettings.locales.ymm.unknown_title}',
  FITMENT_UNKNOWN_TEXT: '{window.themeSettings.locales.ymm.unknown_text}',

  FITMENT_TABLE_VEHICLE_SPECIFIC: `{window.themeSettings.locales.ymm.fitment_table_title || 'Fitment'}`,

  SELECT_TITLE: `{window.themeSettings.locales.ymm[this.title.toLowerCase().replace(' ', '_')] || this.title}`,
};
